<template>
  <v-card class="cursor px-2 pb-0" @click="editReward" tile :ripple="false">
    <v-card-title class="pt-2 pb-0 mb-0 no-flex-wrap">
      <v-spacer></v-spacer>
      <actions-menu
        :canEdit="true"
        :canArchive="!reward.archived"
        @edit="editReward"
        @archive="archiveReward"
        @delete="deleteReward"
      ></actions-menu>
    </v-card-title>
    <div class="d-flex align-center mt-2 mb-3">
      <v-card-text class="text-subtitle-1 pl-3 py-0">
        <span class="one-line">{{ reward.name }}</span>
      </v-card-text>
      <div class="d-flex align-center pr-2">
        <ruby-label :ruby="reward.ruby"></ruby-label>
      </div>
    </div>
    <v-card-actions class="pt-0 pb-2">
      <reward-type-label :reward="reward" class="mr-2"></reward-type-label>
      <v-icon v-if="reward.requireApprove" small color="app-orange">
        mdi-circle
      </v-icon>
    </v-card-actions>
    <v-divider v-if="isLeader" class="mt-1" light></v-divider>
    <v-card-actions v-if="isLeader">
      <div @click.stop="openAssignedUsers()">
        <assigned-users :assignedUsers="assignedUsers"></assigned-users>
      </div>
      <v-spacer></v-spacer>
      <v-btn
        v-if="reward.requireApprove && reward.waitingApproval > 0"
        fab
        x-small
        color="white"
        depressed
        class="btn-shadow"
        @click.stop="openApprovalRewardsModal"
      >
        <v-icon x-small color="app-red" class="mr-n2">mdi-thumb-down</v-icon>
        <v-icon x-small color="app-green">mdi-thumb-up</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import ActionsMenu from '../../shared/ActionsMenu.vue';
import ManageGroupRewardModal from './ManageGroupRewardModal';
import RewardTypeLabel from '../../rewards/RewardTypeLabel';
import RubyLabel from '../../shared/RubyLabel';
import AssignedUsers from '../AssignedUsers';
import AssignedUsersModal from '../AssignedUsersModal';
import ConfirmModal from '../../shared/ConfirmModal';
import ApprovalRewardsModal from './ApprovalRewardsModal';
import GroupRewardInfoModal from './GroupRewardInfoModal';
import {
  GET_APPROVAL_REWARDS,
  ARCHIVE_GROUP_REWARD,
  DELETE_GROUP_REWARD
} from '../../../store/rewards/types';
import rewardTypes from '../../../enums/reward.types';
import manageModalsType from '../../../enums/manage.modals.types';

const GROUP_REWARD_INFO_MODAL_CONFIG = {
  width: '400px'
};

export default {
  name: 'group-reward',
  components: {
    ActionsMenu,
    RewardTypeLabel,
    RubyLabel,
    AssignedUsers
  },
  props: {
    reward: Object,
    isLeader: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['groupMembers']),
    assignedUsers() {
      return this.reward.assignedUsers?.length > 0
        ? this.reward.assignedUsers
        : [];
    }
  },
  data() {
    return {
      rewardTypes
    };
  },
  methods: {
    editReward() {
      if (this.isLeader) {
        this.$root.$modal.show(ManageGroupRewardModal, {
          selectedReward: { ...this.reward },
          members: this.groupMembers,
          title: this.$i18n.t('rewards.editReward'),
          modalType: manageModalsType.edit
        });
      } else {
        this.$root.$modal.show(
          GroupRewardInfoModal,
          {
            reward: this.reward
          },
          GROUP_REWARD_INFO_MODAL_CONFIG
        );
      }
    },
    async openApprovalRewardsModal() {
      const { id } = this.$route.params;

      await this.$store.dispatch(GET_APPROVAL_REWARDS, {
        groupId: id,
        groupRewardId: this.reward.id
      });

      this.$root.$modal.show(
        ApprovalRewardsModal,
        {
          reward: this.reward
        },
        {
          width: '400px'
        }
      );
    },
    openAssignedUsers() {
      this.$root.$modal.show(
        AssignedUsersModal,
        {
          itemName: this.reward.name,
          assignedUsers: this.assignedUsers
        },
        {
          width: '360px'
        }
      );
    },
    async archiveReward() {
      const { id } = this.$route.params;

      this.$store.dispatch(ARCHIVE_GROUP_REWARD, {
        groupId: id,
        rewardId: this.reward.id
      });
    },
    async deleteReward() {
      const confirmed = await this.$root.$modal.show(ConfirmModal, {
        text: this.$root.$t('rewards.deleteRewardConfirm')
      });

      const { id } = this.$route.params;
      const data = {
        groupId: id,
        rewardId: this.reward.id
      };

      if (confirmed) {
        this.$store.dispatch(DELETE_GROUP_REWARD, data);
      }
    }
  }
};
</script>
