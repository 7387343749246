<template>
  <div>
    <v-container fluid v-if="currentGroup" class="pa-0 mb-10">
      <v-row align="center" justify="center">
        <v-col md="6" sm="12" cols="12" class="mt-5 pb-0">
          <group-dashboard-filter
            v-if="isCreatorOrLeader"
            :members="membersList"
            :selectedUserId="selectedUserId"
            @changeDashboard="changeDashboard"
          ></group-dashboard-filter>
        </v-col>
      </v-row>
      <v-row v-if="!loading" id="dashboard-items" class="mt-0 mb-3">
        <v-col md="4" sm="12" cols="12" v-if="visible(0)">
          <group-missions></group-missions>
        </v-col>
        <v-col md="4" sm="12" cols="12" v-if="visible(1)">
          <group-tasks></group-tasks>
        </v-col>
        <v-col md="4" sm="12" cols="12" v-if="visible(2)">
          <group-rewards></group-rewards>
        </v-col>
      </v-row>
    </v-container>
    <bottom-dashboard-nav v-if="$vuetify.breakpoint.smAndDown">
    </bottom-dashboard-nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import GroupMissions from '@/components/groups/missions/GroupMissions';
import GroupTasks from '../../components/groups/tasks/GroupTasks';
import GroupDashboardFilter from '../../components/groups/GroupDashboardFilter';
import GroupRewards from '../../components/groups/rewards/GroupRewards.vue';
import BottomDashboardNav from '@/components/shared/BottomDashboardNav';
import {
  GET_GROUP_DASHBOARD,
  GET_GROUP_MEMBER_DASHBOARD
} from '../../store/dashboard/types';
import { GET_MEMBERS } from '../../store/group-members/types';
import { RESET_TASKS_TYPES_FILTER } from '../../store/tasks/types';
import dailyCronMixin from '../../mixins/daily.cron.mixin';

export default {
  name: 'dashboard',
  components: {
    GroupDashboardFilter,
    GroupMissions,
    GroupTasks,
    GroupRewards,
    BottomDashboardNav
  },
  mixins: [dailyCronMixin],
  computed: {
    ...mapGetters([
      'bottomNavItemsVisibility',
      'currentUser',
      'currentMember',
      'isCreatorOrLeader',
      'currentGroup',
      'groupMembers'
    ]),
    toggleBottomNav() {
      return this.$vuetify.breakpoint.xs;
    },
    membersList() {
      const groupDashboard = {
        id: null,
        userId: null,
        name: this.$i18n.t('groups.groupDashboard'),
        imageUrl: this.currentGroup.imageUrl
      };

      return this.groupMembers && this.groupMembers.length > 0
        ? [groupDashboard, ...this.groupMembers]
        : [groupDashboard];
    },
    selectedUserId() {
      return this.$route.query.userId;
    }
  },
  data() {
    return {
      loading: true,
      dailyCronWatcher: null
    };
  },
  async mounted() {
    this.watchDailyCron();

    if (this.isCreatorOrLeader) {
      await Promise.all([
        this.loadData(this.$route.params.id, this.$route.query.userId),
        this.$store.dispatch(GET_MEMBERS, {
          id: this.$route.params.id,
          params: { dropdown: true }
        })
      ]);
    } else {
      await this.loadData(this.$route.params.id, this.currentMember.userId);
    }

    this.loading = false;
  },
  beforeDestroy() {
    this.dailyCronWatcher();

    this.$store.dispatch(RESET_TASKS_TYPES_FILTER);
  },
  methods: {
    async loadData(groupId, userId) {
      if (userId) {
        await this.$store.dispatch(GET_GROUP_MEMBER_DASHBOARD, {
          groupId,
          userId
        });
      } else {
        await this.$store.dispatch(GET_GROUP_DASHBOARD, groupId);
      }
    },
    async changeDashboard(userId) {
      this.$store.dispatch(RESET_TASKS_TYPES_FILTER);

      const { id } = this.$route.params;

      if (userId) {
        this.$router.replace({
          name: 'groupDashboard',
          query: { userId }
        });
      } else {
        this.$router.replace({ name: 'groupDashboard' });
      }

      this.loadData(id, userId);
    },
    visible(index) {
      if (!this.$vuetify.breakpoint.smAndDown) {
        return true;
      }

      return this.bottomNavItemsVisibility[index];
    },
    watchDailyCron() {
      this.dailyCronWatcher = this.$store.watch(
        (state, getters) => getters.cronData,
        cronData => {
          if (!cronData) return;

          const userId = this.isCreatorOrLeader
            ? this.$route.query.userId
            : this.currentMember.userId;

          this.loadData(this.$route.params.id, userId);
        }
      );
    }
  }
};
</script>
