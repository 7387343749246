<template>
  <v-card tile>
    <v-toolbar flat color="app-blue" dark class="card-header">
      <span class="text-h5 font-weight-regular" v-if="task.id">
        {{ $t(`tasks.approvalTasks`) }}
      </span>
      <v-spacer></v-spacer>
      <v-btn text icon @click="$emit('close')" class="mr-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container grid-list-md class="py-6 px-3 pt-3 pb-0">
      <v-card class="mb-4 border-top-green">
        <h3
          class="two-lines font-weight-regular py-2 text-center app-green--text"
        >
          {{ task.title }}
        </h3>
      </v-card>
      <div v-if="hasTasks">
        <approval-task
          class="mb-2"
          v-for="approvalTask of approvalTasks"
          :key="approvalTask.id"
          :task="approvalTask"
          @approveTask="approveTask"
        ></approval-task>
      </div>
      <div v-else>
        <h3 class="text-subtitle-1 font-weight-light text-center pb-4">
          {{ $t('tasks.noTasksWaitingApprovalFound') }}
        </h3>
      </div>
    </v-container>
    <v-card-actions v-if="hasTasks">
      <v-spacer></v-spacer>
      <v-btn
        color="app-red"
        text
        @click="approveAll(false)"
        :disabled="task.completed"
      >
        <v-icon color="app-red" small class="mr-2">mdi-thumb-down</v-icon>
        {{ $t('common.refuseAll') }}
      </v-btn>
      <v-btn
        color="app-green"
        text
        @click="approveAll(true)"
        :disabled="task.completed"
      >
        <v-icon color="app-green" small class="mr-2">mdi-thumb-up</v-icon>
        {{ $t('common.approveAll') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  APPROVE_ALL_TASKS,
  APPROVE_GROUP_TASK
} from '../../../store/tasks/types';
import ApprovalTask from './ApprovalTask';

export default {
  name: 'approval-task-modal',
  components: {
    ApprovalTask
  },
  props: {
    task: Object
  },
  computed: {
    ...mapGetters(['approvalTasks']),
    hasTasks() {
      return this.approvalTasks.length > 0;
    }
  },
  methods: {
    async approveAll(approved) {
      const { id } = this.$route.params;

      await this.$store.dispatch(APPROVE_ALL_TASKS, {
        groupId: id,
        groupTaskId: this.task.id,
        approved
      });

      if (approved) {
        this.$notify({
          type: 'success',
          text: this.$i18n.t('tasks.аpproveAllTasks')
        });
      } else {
        this.$notify({
          type: 'success',
          text: this.$i18n.t('tasks.refuseToApproveTasks')
        });
      }

      this.$emit('close');
    },
    async approveTask(payload) {
      const { id } = this.$route.params;
      const { userId, taskId, approved } = payload;

      this.$store.dispatch(APPROVE_GROUP_TASK, {
        groupId: id,
        userId: userId,
        taskId: taskId,
        groupTaskId: this.task.id,
        approved
      });

      if (approved) {
        this.$notify({
          type: 'success',
          text: this.$i18n.t('tasks.taskApproved')
        });
      } else {
        this.$notify({
          type: 'success',
          text: this.$i18n.t('tasks.taskRefused')
        });
      }
    }
  }
};
</script>
