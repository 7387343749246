<template>
  <v-card class="border-top-red" tile>
    <v-toolbar color="white" flat>
      <v-toolbar-title class="app-red--text font-weight-light">
        {{ $t('rewards.rewards') }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        v-if="hasActions"
        text
        icon
        @click="open()"
        color="app-red"
        class="mr-0"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container class="pt-3">
      <div v-if="rewards.length > 0">
        <v-row>
          <v-col
            cols="12"
            v-for="reward in rewards"
            :key="reward.id"
            class="mb-2"
          >
            <group-reward
              v-if="reward.assignedUsers && reward.assignedUsers.length > 0"
              :reward="reward"
              :isLeader="true"
            ></group-reward>
            <member-reward
              v-else
              :reward="reward"
              :isLeader="isCreatorOrLeader"
            ></member-reward>
          </v-col>
        </v-row>
      </div>
      <div v-else class="text-center">
        <span class="text-subtitle-1 font-weight-light">
          {{ $t('rewards.noRewardsFound') }}
        </span>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import ManageGroupRewardModal from './ManageGroupRewardModal';
import GroupReward from './GroupReward';
import MemberReward from '../../group-members/rewards/MemberReward';

export default {
  name: 'group-rewards',
  components: {
    GroupReward,
    MemberReward
  },
  computed: {
    ...mapGetters(['rewards', 'groupMembers', 'isCreatorOrLeader']),
    hasActions() {
      return this.isCreatorOrLeader && !this.$route.query.userId;
    }
  },
  methods: {
    open() {
      this.$root.$modal.show(ManageGroupRewardModal, {
        members: this.groupMembers,
        title: this.$i18n.t('rewards.createReward')
      });
    }
  }
};
</script>
