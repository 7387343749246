<template>
  <v-card class="cursor pa-2 pb-0" @click="editTask" tile :ripple="false">
    <v-container class="pa-0">
      <v-card-title class="py-0 mb-2 no-flex-wrap">
        <v-spacer></v-spacer>
        <actions-menu
          v-if="isLeader"
          :canEdit="!task.completed"
          :canArchive="!task.archived"
          @edit="editTask"
          @archive="archiveTask"
          @delete="deleteTask"
        ></actions-menu>
      </v-card-title>
      <v-row align="center" justify="center" class="py-2">
        <v-col
          class="pt-0"
          v-bind:class="{
            'col-12': !task.requireApprove,
            'col-11': task.requireApprove
          }"
        >
          <v-card-title class="text-subtitle-1 py-0">
            <span class="two-lines font-weight-regular">
              {{ task.title }}
            </span>
          </v-card-title>
          <v-card-text class="text-body-2 py-0" v-if="task.notes">
            <span class="font-weight-light one-line">
              {{ task.notes }}
            </span>
          </v-card-text>
        </v-col>
        <v-col v-if="task.requireApprove" cols="1" class="pt-0 px-0">
          <v-icon small color="app-orange"> mdi-circle </v-icon>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions class="pt-0 pb-1">
      <task-tags v-if="task.tags.length > 0" :tags="task.tags"></task-tags>
      <div v-else>&nbsp;</div>
      <v-spacer></v-spacer>
      <v-row align="center" justify="end" class="pr-1">
        <ruby-label :ruby="task.ruby" :text-color="rubyColorText"></ruby-label>
      </v-row>
    </v-card-actions>
    <v-divider class="mt-2" light></v-divider>
    <v-card-actions>
      <div @click.stop="openAssignedUsers()">
        <assigned-users :assignedUsers="assignedUsers"></assigned-users>
      </div>
      <v-spacer></v-spacer>
      <v-btn
        v-if="task.requireApprove && task.waitingApproval > 0"
        fab
        x-small
        color="white"
        depressed
        class="btn-shadow"
        @click.stop="openApprovalTasksModal"
      >
        <v-icon x-small color="app-red" class="mr-n2">mdi-thumb-down</v-icon>
        <v-icon x-small color="app-green">mdi-thumb-up</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  GET_APPROVAL_TASKS,
  ARCHIVE_GROUP_TASK,
  DELETE_GROUP_TASK
} from '../../../store/tasks/types';
import RubyLabel from '../../shared/RubyLabel';
import TaskTags from '../../tasks/TaskTags';
import ManageGroupTaskModal from './ManageGroupTaskModal';
import AssignedUsersModal from '../AssignedUsersModal';
import ActionsMenu from '../../shared/ActionsMenu.vue';
import AssignedUsers from '../AssignedUsers';
import ApprovalTasksModal from './ApprovalTasksModal';
import ConfirmModal from '../../shared/ConfirmModal';
import tasksRubyColorMixin from '../../../mixins/task.ruby.color.mixin';
import manageModalsType from '../../../enums/manage.modals.types';

export default {
  components: {
    RubyLabel,
    TaskTags,
    ActionsMenu,
    AssignedUsers
  },
  props: {
    task: Object,
    isLeader: {
      type: Boolean,
      default: false
    }
  },
  mixins: [tasksRubyColorMixin],
  computed: {
    ...mapGetters(['groupMembers']),
    assignedUsers() {
      return this.task.assignedUsers?.length > 0 ? this.task.assignedUsers : [];
    }
  },
  methods: {
    editTask() {
      this.$root.$modal.show(ManageGroupTaskModal, {
        selectedTask: { ...this.task },
        members: this.groupMembers,
        title: this.$i18n.t(`tasks.edit.${this.task.type}`),
        modalType: manageModalsType.edit
      });

      // if (this.isLeader) {
      //   this.$root.$modal.show(ManageGroupTaskModal, {
      //     selectedTask: { ...this.task }
      //   });
      // } else {
      //   this.$root.$modal.show(
      //     GroupTaskInfoModal,
      //     {
      //       task: this.task
      //     },
      //     GROUP_TASK_INFO_MODAL_CONFIG
      //   );
      // }
    },
    archiveTask() {
      const { id } = this.$route.params;

      this.$store.dispatch(ARCHIVE_GROUP_TASK, {
        groupId: id,
        taskId: this.task.id
      });
    },
    async openApprovalTasksModal() {
      const { id } = this.$route.params;

      await this.$store.dispatch(GET_APPROVAL_TASKS, {
        groupId: id,
        groupTaskId: this.task.id
      });

      this.$root.$modal.show(
        ApprovalTasksModal,
        {
          task: this.task
        },
        {
          width: '400px'
        }
      );
    },
    openAssignedUsers() {
      this.$root.$modal.show(
        AssignedUsersModal,
        {
          itemName: this.task.title,
          assignedUsers: this.assignedUsers
        },
        {
          width: '360px'
        }
      );
    },
    async deleteTask() {
      const confirmed = await this.$root.$modal.show(ConfirmModal, {
        text: this.$i18n.t('tasks.deleteTaskConfirm')
      });

      if (!confirmed) return;

      const { id } = this.$route.params;

      this.$store.dispatch(DELETE_GROUP_TASK, {
        groupId: id,
        task: this.task
      });
    }
  }
};
</script>
