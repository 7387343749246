<template>
  <div>
    <v-card class="border-top-blue" tile>
      <v-toolbar color="white" flat>
        <v-toolbar-title class="app-blue--text font-weight-light">
          {{ $t('tasks.tasks') }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="hasActions"
          text
          icon
          @click="open()"
          color="app-blue"
          class="mr-0"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <div class="py-2">
        <tasks-types-filter
          @onTasksFilterChange="onTasksFilterChange"
        ></tasks-types-filter>
      </div>
    </v-card>
    <div v-for="tasksList of tasksLists" :key="tasksList.name">
      <div v-if="filters[tasksList.name] || !hasSelectedFilters">
        <tasks-list
          :title="$t(tasksList.i18nKey)"
          :tasks="tasksList.tasks"
          :topBorderClass="tasksList.borderClass"
          :titleTextColorClass="tasksList.textClass"
        >
          <template #default="{ task }">
            <group-task
              v-if="task.assignedUsers && task.assignedUsers.length > 0"
              :task="task"
              :isLeader="true"
            ></group-task>
            <member-task
              v-else
              :task="task"
              :isLeader="isCreatorOrLeader"
            ></member-task>
          </template>
        </tasks-list>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  GET_GROUP_TASKS,
  UPDATE_TASKS_TYPES_FILTER,
  RESET_TASKS_TYPES_FILTER
} from '../../../store/tasks/types';
import TasksList from '../../tasks/TasksList';
import GroupTask from './GroupTask';
import MemberTask from '../../group-members/tasks/MemberTask';
import ManageGroupTaskModal from './ManageGroupTaskModal';
import TasksTypesFilter from '../../tasks/TasksTypesFilter';
import taskTypes from '../../../enums/task.types';
import tasksListsService from '../../../services/tasks.lists.service';

export default {
  components: {
    TasksTypesFilter,
    TasksList,
    GroupTask,
    MemberTask
  },
  computed: {
    ...mapGetters([
      'currentGroup',
      'isCreatorOrLeader',
      'currentMember',
      'groupMembers',
      'todos',
      'dailies',
      'weeklies',
      'archivedTasks',
      'tasksTypesFilter'
    ]),
    hasSelectedFilters() {
      return this.tasksTypesFilter.some(f => f.selected);
    },
    filters() {
      return this.tasksTypesFilter.reduce((acc, filter) => {
        acc[filter.type] = filter.selected;
        return acc;
      }, {});
    },
    hasActions() {
      return this.isCreatorOrLeader && !this.$route.query.userId;
    },
    tasksLists() {
      return tasksListsService.getTasksListsConfiguration(
        this.todos,
        this.dailies,
        this.weeklies
      );
    }
  },
  beforeDestroy() {
    this.$store.dispatch(RESET_TASKS_TYPES_FILTER);
  },
  methods: {
    open() {
      this.$root.$modal.show(ManageGroupTaskModal, {
        selectedType: this.getSelectedFilterType(),
        members: this.groupMembers,
        title: this.$i18n.t('tasks.createTask')
      });
    },
    getSelectedFilterType() {
      const filter = this.tasksTypesFilter.find(f => f.selected);

      return filter ? filter.type : taskTypes.todo;
    },
    async onTasksFilterChange(type) {
      await this.$store.dispatch(UPDATE_TASKS_TYPES_FILTER, type);

      let userId = this.isCreatorOrLeader
        ? this.$route.query.userId
        : this.currentMember.userId;

      this.$store.dispatch(GET_GROUP_TASKS, {
        groupId: this.$route.params.id,
        userId,
        params: {
          archived: false,
          type: this.tasksTypesFilter.filter(f => f.selected).map(f => f.type)
        }
      });
    }
  }
};
</script>
