<template>
  <v-card tile :ripple="false">
    <v-container fluid>
      <v-row justify="center" align="center">
        <v-col md="3" sm="4" cols="12">
          <user-avatar
            :user="mission.user"
            :size="60"
            textSizeClass="title"
          ></user-avatar>
        </v-col>
        <v-col md="9" sm="8" cols="12" class="pt-2 pl-1">
          <v-card-title class="pa-0">
            {{ mission.user.name }}
          </v-card-title>
          <v-card-actions class="pb-0">
            <v-spacer></v-spacer>
            <approval-actions @approve="approveMission"></approval-actions>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import ApprovalActions from '../ApprovalActions';
import UserAvatar from '../../shared/UserAvatar';

export default {
  name: 'approval-mission',
  components: {
    ApprovalActions,
    UserAvatar
  },
  props: {
    mission: {
      type: Object,
      require: true
    }
  },
  methods: {
    approveMission(approved) {
      this.$emit('approveMission', {
        userId: this.mission.user.id,
        missionId: this.mission.id,
        approved
      });
    }
  }
};
</script>
