<template>
  <v-card tile :ripple="false">
    <v-container fluid>
      <v-row justify="center" align="center">
        <v-col cols="3">
          <user-avatar
            :user="reward.user"
            :size="60"
            textSizeClass="title"
          ></user-avatar>
        </v-col>
        <v-col cols="9" class="pt-2 pl-1">
          <v-card-title class="pa-0">
            {{ reward.user.name }}
          </v-card-title>
          <v-card-text v-if="reward.result" class="pl-0 pb-0 text-body-1">
            <strong>{{ $t('common.result') }}: </strong>
            <span class="font-weight-light">{{ reward.result }}</span>
          </v-card-text>
          <v-card-actions class="pb-0">
            <v-spacer></v-spacer>
            <approval-actions @approve="approveReward"></approval-actions>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import ApprovalActions from '../ApprovalActions';
import UserAvatar from '../../shared/UserAvatar';

export default {
  name: 'approval-reward',
  components: {
    ApprovalActions,
    UserAvatar
  },
  props: {
    reward: {
      type: Object,
      require: true
    }
  },
  methods: {
    approveReward(approved) {
      this.$emit('approveReward', {
        userId: this.reward.userId,
        rewardId: this.reward.id,
        approved
      });
    }
  }
};
</script>
