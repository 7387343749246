<template>
  <v-card tile>
    <v-toolbar flat color="app-blue" dark class="card-header">
      <span class="text-h5 font-weight-regular" v-if="mission.id">
        {{ $t(`missions.approvalMissions`) }}
      </span>
      <v-spacer></v-spacer>
      <v-btn text icon @click="$emit('close')" class="mr-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container grid-list-md class="py-6 px-3 pt-3 pb-0">
      <v-card class="mb-4 border-top-green">
        <h3
          class="two-lines font-weight-regular py-2 text-center app-green--text"
        >
          {{ mission.name }}
        </h3>
      </v-card>
      <div v-if="hasMissions">
        <approval-mission
          class="mb-2"
          v-for="approvalMission of approvalMissions"
          :key="approvalMission.id"
          :mission="approvalMission"
          @approveMission="approveMission"
        ></approval-mission>
      </div>
      <div v-else>
        <h3 class="text-subtitle-1 font-weight-light text-center pb-4">
          {{ $t('missions.noMissionsWaitingApprovalFound') }}
        </h3>
      </div>
    </v-container>
    <v-card-actions v-if="hasMissions">
      <v-spacer></v-spacer>
      <v-btn
        color="app-red"
        text
        @click="approveAll(false)"
        :disabled="!allTasksCompleted"
      >
        <v-icon color="app-red" small class="mr-2">mdi-thumb-down</v-icon>
        {{ $t('common.refuseAll') }}
      </v-btn>
      <v-btn
        color="app-green"
        text
        @click="approveAll(true)"
        :disabled="!allTasksCompleted"
      >
        <v-icon color="app-green" small class="mr-2">mdi-thumb-up</v-icon>
        {{ $t('common.approveAll') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  APPROVE_ALL_MISSIONS,
  APPROVE_GROUP_MISSION
} from '../../../store/missions/types';
import ApprovalMission from './ApprovalMission';

export default {
  name: 'approval-mission-modal',
  components: {
    ApprovalMission
  },
  props: {
    mission: Object
  },
  computed: {
    ...mapGetters(['approvalMissions']),
    hasMissions() {
      return this.approvalMissions.length > 0;
    },
    allTasksCompleted() {
      return this.mission.tasks.filter(t => t.completed).length === 0;
    }
  },
  methods: {
    async approveAll(approved) {
      const { id } = this.$route.params;

      await this.$store.dispatch(APPROVE_ALL_MISSIONS, {
        groupId: id,
        groupMissionId: this.mission.id,
        approved
      });

      if (approved) {
        this.$notify({
          type: 'success',
          text: this.$i18n.t('missions.аpproveAllMissions')
        });
      } else {
        this.$notify({
          type: 'success',
          text: this.$i18n.t('missions.refuseToApproveMissions')
        });
      }

      this.$emit('close');
    },
    async approveMission(payload) {
      const { id } = this.$route.params;
      const { userId, missionId, approved } = payload;

      this.$store.dispatch(APPROVE_GROUP_MISSION, {
        groupId: id,
        userId,
        missionId,
        groupMissionId: this.mission.id,
        approved
      });

      if (approved) {
        this.$notify({
          type: 'success',
          text: this.$i18n.t('missions.missionApproved')
        });
      } else {
        this.$notify({
          type: 'success',
          text: this.$i18n.t('missions.missionRefused')
        });
      }
    }
  }
};
</script>
