<template>
  <v-select
    v-model="userId"
    :items="members"
    item-value="userId"
    item-text="name"
    solo
    @change="selectMember"
  >
    <template v-slot:selection="{ item }">
      <div v-if="item.userId">
        <user-avatar :user="item" :size="36" class="mr-5"></user-avatar>
      </div>
      <v-list-item-avatar v-else>
        <v-img contain :src="getGroupImage(item.imageUrl)"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          {{ item.name }}
        </v-list-item-title>
      </v-list-item-content>
    </template>
    <template v-slot:item="{ item }">
      <div v-if="item.userId">
        <user-avatar :user="item" :size="36" class="mr-5"></user-avatar>
      </div>
      <v-list-item-avatar v-else>
        <v-img contain :src="getGroupImage(item.imageUrl)"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ item.name }}</v-list-item-title>
      </v-list-item-content>
    </template>
  </v-select>
</template>

<script>
import UserAvatar from '../shared/UserAvatar.vue';

export default {
  components: {
    UserAvatar
  },
  props: {
    members: {
      type: Array,
      default: () => []
    },
    selectedUserId: String
  },
  data() {
    return {
      userId: null
    };
  },
  mounted() {
    if (this.selectedUserId) {
      this.userId = this.selectedUserId;
    }
  },
  methods: {
    getGroupImage(imageUrl) {
      return imageUrl ? imageUrl : require('../../assets/images/no-image.png');
    },
    selectMember() {
      this.$emit('changeDashboard', this.userId);
    }
  }
};
</script>
