<template>
  <v-card
    v-if="mission.id"
    class="px-2 pb-0 cursor"
    :class="topBorderClass"
    @click="editMission"
    tile
    :ripple="false"
  >
    <v-container class="px-3 py-0">
      <v-card-title
        class="no-flex-wrap pt-2 pl-0 mb-2"
        v-bind:class="{ 'pb-0': isLeader, 'pb-2': !isLeader }"
      >
        <v-chip
          v-if="!isLeader && mission.group"
          class="ml-0"
          small
          label
          color="app-green"
          dark
        >
          <span class="text-body-2 font-weight-medium">
            {{ mission.group.name }}
          </span>
        </v-chip>
        <v-spacer></v-spacer>
        <actions-menu
          v-if="isLeader"
          class="mr-n-3"
          :canEdit="
            mission.status === statuses.active ||
            mission.status === statuses.pending
          "
          :canArchive="mission.status !== statuses.archived"
          @edit="editMission"
          @archive="archiveMission"
          @delete="deleteMission"
        ></actions-menu>
      </v-card-title>
      <v-row align="center">
        <v-col
          class="pt-0"
          v-bind:class="{
            'col-12': !mission.requireApprove,
            'col-11': mission.requireApprove
          }"
        >
          <v-card-title class="text-h6 pl-0 pt-0 pb-0">
            <span
              v-if="!isDetailPage"
              @click.stop="navigateToDetailPage"
              class="text-h6 font-weight-regular two-lines app-blue--text router-link text-truncate"
            >
              {{ mission.name }}
            </span>
            <span v-else class="two-lines font-weight-regular">
              {{ mission.name }}
            </span>
          </v-card-title>
          <div class="text-subtitle-1 app-dark-gray--text font-weight-regular">
            {{ mission.notes }}
          </div>
          <span class="text-body-2 font-weight-light">
            {{ mission.from | dateFormat }} -
            {{ mission.to | dateFormat }}
          </span>
        </v-col>
        <v-col v-if="mission.requireApprove" cols="1" class="pt-0 px-0">
          <v-icon v-if="!approveMarked" small color="app-orange">
            mdi-circle
          </v-icon>
          <v-icon v-else-if="mission.approved" small color="app-green">
            mdi-thumb-up
          </v-icon>
          <v-icon v-else small color="app-red">mdi-thumb-down</v-icon>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions class="pt-0 pb-1 px-2">
      <mission-progress-bar :mission="mission"></mission-progress-bar>
    </v-card-actions>
    <v-card-actions class="pt-1">
      <mission-status-label :mission="mission"></mission-status-label>
      <v-spacer></v-spacer>
      <v-row align="center" justify="end" class="pr-3">
        <ruby-label
          :ruby="mission.ruby"
          :text-color="textRubyColor"
        ></ruby-label>
      </v-row>
    </v-card-actions>
    <v-divider v-if="isLeader" class="mt-1" light></v-divider>
    <v-card-actions v-if="isLeader">
      <div @click.stop="openAssignedUsers()">
        <assigned-users :assignedUsers="assignedUsers"></assigned-users>
      </div>
      <v-spacer></v-spacer>
      <v-btn
        v-if="mission.requireApprove && mission.waitingApproval > 0"
        fab
        x-small
        color="white"
        depressed
        class="btn-shadow"
        @click.stop="openApprovalMissionsModal"
      >
        <v-icon x-small color="app-red" class="mr-n2">mdi-thumb-down</v-icon>
        <v-icon x-small color="app-green">mdi-thumb-up</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  GET_APPROVAL_MISSIONS,
  ARCHIVE_GROUP_MISSION,
  DELETE_GROUP_MISSION
} from '../../../store/missions/types';
import ActionsMenu from '../../shared/ActionsMenu.vue';
import MissionProgressBar from '../../missions/MissionProgressBar';
import MissionStatusLabel from '../../missions/MissionStatusLabel';
import RubyLabel from '../../shared/RubyLabel';
import ManageGroupMissionModal from './ManageGroupMissionModal';
import GroupMissionInfoModal from './GroupMissionInfoModal';
import AssignedUsers from '../AssignedUsers';
import AssignedUsersModal from '../AssignedUsersModal';
import ApprovalMissionsModal from './ApprovalMissionsModal';
import ConfirmModal from '../../shared/ConfirmModal';
import missionRubyColorMixin from '../../../mixins/mission.ruby.color.mixin';
import manageModalsType from '../../../enums/manage.modals.types';
import missionStatuses from '../../../enums/mission.statuses';

const GROUP_MISSION_INFO_MODAL_CONFIG = {
  width: '400px'
};

export default {
  name: 'group-mission',
  components: {
    ActionsMenu,
    MissionProgressBar,
    MissionStatusLabel,
    RubyLabel,
    AssignedUsers
  },
  props: {
    mission: {
      type: Object,
      required: true
    },
    isDetailPage: {
      type: Boolean,
      default: false
    },
    topBorderClass: {
      type: String,
      default: 'border-top-blue'
    },
    isLeader: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    approveMarked() {
      return (
        this.mission.approved !== null && this.mission.approved !== undefined
      );
    },
    assignedUsers() {
      return this.mission.assignedUsers?.length > 0
        ? this.mission.assignedUsers
        : [];
    },
    statuses() {
      return missionStatuses;
    }
  },
  mixins: [missionRubyColorMixin],
  methods: {
    editMission() {
      if (this.isLeader) {
        this.$root.$modal.show(ManageGroupMissionModal, {
          selectedMission: { ...this.mission },
          title: this.$i18n.t('missions.editMission'),
          modalType: manageModalsType.edit
        });
      } else {
        this.$root.$modal.show(
          GroupMissionInfoModal,
          {
            mission: this.mission
          },
          GROUP_MISSION_INFO_MODAL_CONFIG
        );
      }
    },
    async openApprovalMissionsModal() {
      const { id } = this.$route.params;

      await this.$store.dispatch(GET_APPROVAL_MISSIONS, {
        groupId: id,
        groupMissionId: this.mission.id
      });

      this.$root.$modal.show(
        ApprovalMissionsModal,
        {
          mission: this.mission
        },
        {
          width: '400px'
        }
      );
    },
    openAssignedUsers() {
      this.$root.$modal.show(
        AssignedUsersModal,
        {
          itemName: this.mission.name,
          assignedUsers: this.assignedUsers
        },
        {
          width: '360px'
        }
      );
    },
    archiveMission() {
      const { id } = this.$route.params;

      this.$store.dispatch(ARCHIVE_GROUP_MISSION, {
        groupId: id,
        missionId: this.mission.id
      });
    },
    async deleteMission() {
      const confirmed = await this.$root.$modal.show(ConfirmModal, {
        text: this.$i18n.t('missions.deleteMissionConfirm')
      });

      if (!confirmed) return;

      const { id } = this.$route.params;

      this.$store.dispatch(DELETE_GROUP_MISSION, {
        groupId: id,
        missionId: this.mission.id
      });
    },
    navigateToDetailPage() {
      this.$router.push({ path: `/missions/${this.mission.id}` });
    }
  }
};
</script>
