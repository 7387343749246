<template>
  <div>
    <group-missions-toolbar :missions="missions"></group-missions-toolbar>
    <div class="mt-2" v-if="missions && missions.length > 0">
      <v-card tile v-if="currentMission">
        <group-mission
          v-if="
            currentMission.assignedUsers &&
            currentMission.assignedUsers.length > 0
          "
          :isLeader="true"
          :mission="currentMission"
          :isDetailPage="true"
          top-border-class="no-border"
        ></group-mission>
        <div v-else>
          <member-mission
            top-border-class="no-border"
            :mission="currentMission"
            :isLeader="isCreatorOrLeader"
            :isDetailPage="true"
          ></member-mission>
          <div class="py-2 px-3" depressed v-if="canCompleteMission">
            <v-btn dark color="app-blue" block @click="completeMission()" tile>
              {{ $t('missions.completeMissionBtn') }}
            </v-btn>
          </div>
        </div>
      </v-card>
      <div class="mt-2">
        <group-mission-tasks
          :mission="currentMission"
          top-border-class="no-border"
          :title="$t('missions.tasks')"
          :isLeader="isCreatorOrLeader"
        ></group-mission-tasks>
      </div>
    </div>
    <v-card v-else class="text-center mt-2 py-1" tile>
      <span class="text-subtitle-1 font-weight-light">
        {{ $t('missions.noMissionsFound') }}
      </span>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import GroupMissionsToolbar from './GroupMissionsToolbar';
import GroupMission from './GroupMission';
import GroupMissionTasks from './tasks/GroupMissionTasks';
import MemberMission from '../../group-members/missions/MemberMission';
import missionStatuses from '../../../enums/mission.statuses';
import { COMPLETE_GROUP_MISSION } from '../../../store/missions/types';

export default {
  name: 'group-missions',
  components: {
    GroupMissionsToolbar,
    GroupMission,
    GroupMissionTasks,
    MemberMission
  },
  computed: {
    ...mapGetters(['missions', 'currentMission', 'isCreatorOrLeader']),
    canCompleteMission() {
      return (
        this.currentMission.id &&
        this.currentMission.status === missionStatuses.active &&
        this.currentMission.tasks.filter(t => !t.completed).length === 0 &&
        !this.isCreatorOrLeader
      );
    }
  },
  methods: {
    async completeMission() {
      const { diff } = await this.$store.dispatch(
        COMPLETE_GROUP_MISSION,
        this.currentMission
      );

      if (diff) {
        this.notifySuccess(diff, 'users.stats.success');
      }
    }
  }
};
</script>
