<template>
  <v-card class="border-top-orange" tile>
    <v-toolbar color="white" flat>
      <v-toolbar-title class="app-orange--text font-weight-light">
        {{ $t('missions.missions') }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-if="hasMissions">
        <template v-slot:activator="{ on }">
          <v-btn dark text icon color="app-orange" class="ma-0 ml-1" v-on="on">
            <v-icon>mdi-swap-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="mission of missions"
            :key="mission.id"
            @click="changeGroupMission(mission)"
          >
            <mission-small-status-label
              :status="mission.status"
            ></mission-small-status-label>
            <v-list-item-title class="ml-1">{{
              mission.name
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        v-if="hasActions"
        text
        icon
        color="app-orange"
        class="mr-0"
        @click="add()"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import MissionSmallStatusLabel from '../../missions/MissionSmallStatusLabel';
import ManageGroupMissionModal from './ManageGroupMissionModal';
import { GET_CURRENT_GROUP_MISSION } from '../../../store/missions/types';

export default {
  name: 'group-missions-toolbar',
  components: {
    MissionSmallStatusLabel
  },
  props: {
    missions: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters(['isCreatorOrLeader', 'currentMember']),
    hasMissions() {
      return this.missions && this.missions.length > 0;
    },
    hasActions() {
      return this.isCreatorOrLeader && !this.$route.query.userId;
    }
  },
  methods: {
    async add() {
      await this.$root.$modal.show(ManageGroupMissionModal, {
        title: this.$i18n.t('missions.createMission')
      });
    },
    changeGroupMission(mission) {
      let userId = this.isCreatorOrLeader
        ? this.$route.query.userId
        : this.currentMember.userId;

      this.$store.dispatch(GET_CURRENT_GROUP_MISSION, {
        groupId: this.$route.params.id,
        userId,
        missionId: mission.id
      });
    }
  }
};
</script>
