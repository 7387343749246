<template>
  <v-card tile>
    <v-toolbar flat color="app-blue" dark class="card-header">
      <span class="text-h5 font-weight-regular" v-if="reward.id">
        {{ $t(`rewards.approvalRewards`) }}
      </span>
      <v-spacer></v-spacer>
      <v-btn text icon @click="$emit('close')" class="mr-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container grid-list-md class="py-6 px-3 pt-3 pb-0">
      <v-card class="mb-4 border-top-green">
        <h3
          class="two-lines font-weight-regular py-2 text-center app-green--text"
        >
          {{ reward.name }}
        </h3>
      </v-card>
      <div v-if="hasRewards">
        <approval-reward
          class="mb-2"
          v-for="approvalReward of approvalRewards"
          :key="approvalReward.id"
          :reward="approvalReward"
          @approveReward="approveReward"
        ></approval-reward>
      </div>
      <div v-else>
        <h3 class="text-subtitle-1 font-weight-light text-center pb-4">
          {{ $t('rewards.noRewardsWaitingApprovalFound') }}
        </h3>
      </div>
    </v-container>
    <v-card-actions v-if="hasRewards">
      <v-spacer></v-spacer>
      <v-btn
        color="app-red"
        text
        @click="approveAll(false)"
        :disabled="reward.completed"
      >
        <v-icon color="app-red" small class="mr-2">mdi-thumb-down</v-icon>
        {{ $t('common.refuseAll') }}
      </v-btn>
      <v-btn color="app-green" text @click="approveAll(true)">
        <v-icon color="app-green" small class="mr-2">mdi-thumb-up</v-icon>
        {{ $t('common.approveAll') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  APPROVE_ALL_REWARDS,
  APPROVE_GROUP_REWARD
} from '../../../store/rewards/types';
import ApprovalReward from './ApprovalReward';

export default {
  name: 'approval-reward-modal',
  components: {
    ApprovalReward
  },
  props: {
    reward: Object
  },
  computed: {
    ...mapGetters(['approvalRewards']),
    hasRewards() {
      return this.approvalRewards.length > 0;
    }
  },
  methods: {
    async approveAll(approved) {
      const { id } = this.$route.params;

      await this.$store.dispatch(APPROVE_ALL_REWARDS, {
        groupId: id,
        groupRewardId: this.reward.id,
        approved
      });

      if (approved) {
        this.$notify({
          type: 'success',
          text: this.$i18n.t('rewards.аpproveAllRewards')
        });
      } else {
        this.$notify({
          type: 'success',
          text: this.$i18n.t('rewards.refuseToApproveRewards')
        });
      }

      this.$emit('close');
    },
    async approveReward(payload) {
      const { id } = this.$route.params;
      const { userId, rewardId, approved } = payload;

      this.$store.dispatch(APPROVE_GROUP_REWARD, {
        groupId: id,
        userId: userId,
        rewardId,
        groupRewardId: this.reward.id,
        approved
      });

      if (approved) {
        this.$notify({
          type: 'success',
          text: this.$i18n.t('rewards.rewardApproved')
        });
      } else {
        this.$notify({
          type: 'success',
          text: this.$i18n.t('rewards.rewardRefused')
        });
      }
    }
  }
};
</script>
